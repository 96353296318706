<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="items"
                    :fields="fields"
                    @row-dblclicked="editForm"
                    @row-clicked="clicked"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(is_active)="data">
                        <span v-if="data.item.is_active == true">
                            Активен
                        </span>
                        <span v-else>
                            Не активен
                        </span>
                    </template>
                    <template #cell(positions)="data">
                            <!-- <router-link :to="{ name: 'position', query: { position_id: data.item.id } }"> -->
                                <span @dblclick="getPositions(data)" style="color: blue;">
                                    (***)
                                </span>
                            <!-- </router-link> -->
                    </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['items'],
    data(){
        return {
            fields: [ 
        { key: 'checkbox', label: '', thStyle: {width: '30px'}},
        { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
        { key: 'name', label: 'Имя', sortable: true, thStyle: {width: '150px'}},
        { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
        { key: 'positions', label: 'Должности', sortable: true, thStyle: {width: '150px'}}
      ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(id){
            this.$emit('editForm', id.id)
        },
        getPositions(data) {
            this.$store.commit('draggableTab/ADD_TAB', ['Должности ' + data.item.id, `/staff/position/index`, data.item.id])
            this.$router.push({name: 'position', query:{position_id: data.item.id}})
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style> 